import { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";

function App() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const iframeParam = params.get('iframe');
    if (iframeParam !== '1') {
      window.location.href = "https://varidee.de/de/content/14-baureihe-d30";
    }
  }, []);

  return (
    <div className="wrapper">
      {/* <Header /> */}
      <Main />
      {/* <Footer /> */}
    </div>
  );
}

export default App;