import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowAltCircleDown } from "react-icons/fa";
import Showcase from "../images/assembly-transparent.png";
import Part from "../images/3.png";
import Part2 from "../images/7.png";
import Part3 from "../images/9.png";
import Part4 from "../images/10.png";
import Part5 from "../images/11.png";
import Part6 from "../images/12.png";
import Part7 from "../images/13.png";
import Part8 from "../images/14.png";
import Part9 from "../images/15.png";
import Part10 from "../images/16.png";
import Part11 from "../images/17.png";
import Part12 from "../images/18.png";
import Part13 from "../images/19.png";
import Part14 from "../images/20.png";
import Part15 from "../images/21.png";
import Part16 from "../images/22.png";
import Part17 from "../images/26.png";
import Part18 from "../images/27.png";
import Part19 from "../images/29.png";
import Part20 from "../images/30.png";
import Part21 from "../images/31.png";
import Part22 from "../images/32.png";
import Part23 from "../images/34.png";
import Part24 from "../images/35.png";
//Highlited Part
import Highlight1 from "../images/1.svg";
import Highlight2 from "../images/2.svg";
import Highlight3 from "../images/4.svg";
import Highlight4 from "../images/5.svg";
import Highlight5 from "../images/6.svg";
import Highlight6 from "../images/8.svg";
import Highlight7 from "../images/23.svg";
import Highlight8 from "../images/24.svg";
import Highlight9 from "../images/25.svg";
import Highlight10 from "../images/28.svg";
import Highlight11 from "../images/33.svg";
import Highlight12 from "../images/35.svg";
import Highlight13 from "../images/36.svg";
import Highlight14 from "../images/37.svg";
import Highlight15 from "../images/38.svg";
import Highlight16 from "../images/39.svg";
//Dot
import Dot from "../images/dot.gif";

export default function Main() {
  return (
    <div className="main">
      <div
        style={{
          position: "absolute",
          width: 70,
          height: 70,
          left: 45,
          top: "700px",
          padding: 30,
          zIndex: 999,
        }}
      >
        <div className="container">
          <div className="field">
            <div className="scroll"></div>
          </div>
        </div>
      </div>
      <h1>
        Entdecken Sie unsere neuen Bauteile der <span>Baureihe D30</span>
      </h1>
      <p className="definition-text">
        Bewegen Sie den Mauszeiger über die Elemente und klicken Sie, um den
        Artikel in unserem Online-Shop zu besuchen
      </p>
      <p className="showButton">
        <a
          href="https://varidee.de/en/1106-featured-products"
          target="_blank"
          style={{ color: "white", textDecoration: "none" }} rel="noreferrer"
        >
          Alle Bauteile im Überblick
        </a>
      </p>
      <div className="showcasewrapper">
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/verbindungselemente-d30/3060-v-d30-al.html"
              target="_blank" rel="noreferrer"
            >
              Verbinder D30 AI <FaArrowRight />
            </a>
          </span>
          <img src={Highlight3} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/gleitleisten/3106-gl-d30-g-z.html"
              target="_blank" rel="noreferrer"
            >
              Gleitleiste D30 <FaArrowRight />
            </a>
          </span>
          <img src={Highlight16} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/verbindungselemente-d30/3064-stv-d30-90-r25-al.html"
              target="_blank" rel="noreferrer"
            >
              Stoßverbinder D30-90 R25 AI <FaArrowRight />
            </a>
          </span>
          <img src={Highlight10} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span style={{ width: 200 }}>
            <a
              href="https://varidee.de/de/beschriftung/3088-bschp-d30-100-t.html"
              target="_blank" rel="noreferrer"
            >
              Beschriftungsprofil D30-100, transparent <FaArrowRight />
            </a>
          </span>
          <img src={Highlight6} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span style={{ width: 200 }}>
            <a
              href="https://varidee.de/de/rollenbahn/3110-rb-st-d30-esd-s-sp-z.html"
              target="_blank" rel="noreferrer"
            >
              Rollenbahn St D30 ESD mit Bordkante, schwarz <FaArrowRight />
            </a>
          </span>
          <img src={Highlight15} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3086-rb-st-d30-an-u.html"
              target="_blank" rel="noreferrer"
            >
              Rollenbahn St Aufnahme U D30 <FaArrowRight />
            </a>
          </span>
          <img src={Highlight8} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/abdeckkappen-d30/3070-ak-d30-r-g.html"
              target="_blank" rel="noreferrer"
            >
              Abdeckkappe Rohr D30, grau <FaArrowRight />
            </a>
          </span>
          <img src={Highlight5} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/profile-d30/3112-pr-d30-al-s-z.html"
              target="_blank" rel="noreferrer"
            >
              Profilrohr D30 AI schwer <FaArrowRight />
            </a>
          </span>
          <img src={Highlight2} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span style={{ width: 200 }}>
            <a
              href="https://varidee.de/de/rollenbahn/3109-rb-st-d30-g-sp-z.html"
              target="_blank" rel="noreferrer"
            >
              Rollenbahn St D30, mit Bordkante grau <FaArrowRight />
            </a>
          </span>
          <img src={Highlight14} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span style={{ width: 200 }}>
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3084-rb-st-d30-an-h43.html"
              target="_blank" rel="noreferrer"
            >
              Rollenbahn St Aufnahme D30 mit Anschlag H43 <FaArrowRight />
            </a>
          </span>
          <img src={Highlight7} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/profile-d30/3111-pr-d30-al-z.html"
              target="_blank" rel="noreferrer"
            >
              Profilrohr D30 AI <FaArrowRight />
            </a>
          </span>
          <img src={Highlight2} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/rollenbahn/3107-rb-st-d30-g-z.html"
              target="_blank" rel="noreferrer"
            >
              Rollenbahn St D30, grau <FaArrowRight />
            </a>
          </span>
          <img src={Highlight12} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/rollenbahn/3108-rb-st-d30-esd-s-z.html"
              target="_blank" rel="noreferrer"
            >
              Rollenbahn St D30 ESD, schwarz <FaArrowRight />
            </a>
          </span>
          <img src={Highlight13} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3085-rb-st-d30-an-i.html"
              target="_blank" rel="noreferrer"
            >
              Rollenbahn St Aufnahme I D30 <FaArrowRight />
            </a>
          </span>
          <img src={Highlight9} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot" style={{ display: "none" }}>
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/profile-d30/3112-pr-d30-al-s-z.html"
              target="_blank" rel="noreferrer"
            >
              Profilrohr D30 AI schwer <FaArrowRight />
            </a>
          </span>
          <img src={Highlight2} alt="Highlight1" className="highlight" />
        </div>
        <div className="dot">
          <img src={Dot} alt="dot" />
          <span>
            <a
              href="https://varidee.de/de/abdeckkappen-d30/3068-ak-d30-g-g.html"
              target="_blank" rel="noreferrer"
            >
              Abdeckkappe D30 gewölbt R15, grau <FaArrowRight />
            </a>
          </span>
          <img src={Highlight4} alt="Highlight1" className="highlight" />
        </div>
        <img src={Showcase} alt="showcase" className="itemwrapper" />
      </div>
      <div className="partwrapper">
        <h1>
          Weitere Komponenten der <span>D30-Serie</span>
        </h1>
        <div className="innerparts">
          <div className="singlepart">
            <a
              href="https://varidee.de/de/abdeckkappen-d30/3069-ak-d30-f-g.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part24} alt="Part" />
            </a>
            <div className="description">Abdeckkappe D30 flach, grau</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/verbindungselemente-d30/3063-a8-d30-90-al.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part} alt="Part" />
            </a>
            <div className="description">Anschluss 8 D30-90° Al</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/verbindungselemente-d30/3061-a8-d30-al.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part2} alt="Part" />
            </a>
            <div className="description">Adapter 8 D30 Al</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/beschriftung/3089-bscht-d30-100.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part3} alt="Part" />
            </a>
            <div className="description">Beschriftungsträger D30-100</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3079-flb-d30.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part4} alt="Part" />
            </a>
            <div className="description">Flächenbefestigung D30, grau</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3083-gwe-d30-al-m10.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part5} alt="Part" />
            </a>
            <div className="description">
              Gewindeeinsatz Profilrohr D30 M10 Al
            </div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3082-gwe-d30-al-m8.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part6} alt="Part" />
            </a>
            <div className="description">
              Gewindeeinsatz Profilrohr D30 M8 Al
            </div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3075-ksch-d30-g.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part7} alt="Part" />
            </a>
            <div className="description">Klemmschelle D30, grau</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/verbindungselemente-d30/3067-krv-d30-al.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part8} alt="Part" />
            </a>
            <div className="description">Kreuzverbinder D30 Al</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/verbindungselemente-d30/3066-kstv-d30-al.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part9} alt="Part" />
            </a>
            <div className="description">Kugel-Stoßverbinder D30 AI</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/verbindungselemente-d30/3072-kuv-d30-al.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part10} alt="Part" />
            </a>
            <div className="description">Kugelverbinder D30 AI</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3073-mb-d30-al.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part11} alt="Part" />
            </a>
            <div className="description">Multiblock D30 AI</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/verbindungselemente-d30/3065-pv-d30-60-al.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part12} alt="Part" />
            </a>
            <div className="description">Parallelverbinder D30-60 AI</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/laufrollen/3080-r-d30-esd-d46-20-s.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part13} alt="Part" />
            </a>
            <div className="description">Rolle D46/D30-20 ESD, schwarz</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3090-rs8-al-an-d30-ma.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part14} alt="Part" />
            </a>
            <div className="description">
              Rollenbahn AI Aufnahme D30 mit Anschlag H43
            </div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3092-rs8-al-an-d30-i.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part15} alt="Part" />
            </a>
            <div className="description">Rollenbahn AI Aufnahme I D30</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3091-rs8-al-an-d30-u.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part16} alt="Part" />
            </a>
            <div className="description">Rollenbahn AI Aufnahme U D30</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3087-rb-st-d30-ust.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part17} alt="Part" />
            </a>
            <div className="description">Rollenbahn St Unterstützung D30</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3074-sch8-d30.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part18} alt="Part" />
            </a>
            <div className="description">Schelle 8 D30 St, verzinkt</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3078-tpb-d30.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part19} alt="Part" />
            </a>
            <div className="description">Tischplattenbefestigung D30</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3077-tpe-l-d30.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part20} alt="Part" />
            </a>
            <div className="description">
              Tischplatteneinhängung Lasche D30 St, verzinkt
            </div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3076-tpe-st-d30.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part21} alt="Part" />
            </a>
            <div className="description">
              Tischplatteneinhängung Strebe D30 St, verzinkt
            </div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/befestigungselemente-d30/3081-uvh-d30.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part22} alt="Part" />
            </a>
            <div className="description">Universal-Halter D30, grau</div>
          </div>
          <div className="singlepart">
            <a
              href="https://varidee.de/de/verbindungselemente-d30/3071-wv-d30-45-al.html"
              target="_blank" rel="noreferrer"
            >
              <img src={Part23} alt="Part" />
            </a>
            <div className="description">Verbinder D30-45° AI</div>
          </div>
        </div>
      </div>
    </div>
  );
}
